table td, table th{
   font-family: "Raleway",sans-serif!important;
}

.certTable td{
   min-width: 200px;
   padding:10px 5px!important;
}

.certTable tr.MuiTableRow-root td:first-child{
   padding:10px 5px!important;
}
