.linkFiliera,
.linkFiliera:link,
.linkFiliera:active,
.linkFiliera:visited,
.linkFiliera:hover{
   color:#3C4858;
}

.infoContainer{
   background: rgba(255,255,255,0.8);
   width: 50%; 
   max-width: 50%; 
   position: absolute; 
   right: 0; 
   top: 0; 
   padding: 20px; 
   height: 100%;
}
.leaflet-left .leaflet-control{
   margin-left:25px;
}

.metaContainer{
   padding:60px;
   padding-bottom:20px;
}



.materialsContainer{
   padding:60px;
   padding-top:0;
   display: flex;
   justify-content: space-between;
}

.metaInnerContainer{
   display:flex;
   flex-direction: column;
   align-items: center;
   justify-content: flex-start;
}

.metaInnerContainerCenter{
   justify-content: center;
}

.materialsDialog .MuiDialog-paper{
   width:60vw;
   min-height: 50vh;
}

.inputList{
   text-align: center;
}

.materialsDialog .inputList{
   float:left;
   width:50%;
   padding:15px 0;
   border-bottom:1px solid#eaeaea;
   text-align: left;
}

.openTimeStampContainer{
    overflow-x: scroll;
    margin-top:0px;
    width:100%!important;
    -webkit-box-shadow: 0px 0px 10px 6px rgba(0,0,0,0.13);
    -moz-box-shadow: 0px 0px 10px 6px rgba(0,0,0,0.13);
    box-shadow: 0px 0px 10px 6px rgba(0,0,0,0.13);
}

.openTimeTable tr.MuiTableRow-root th{
   font-size:14px;
   line-height: 120%;
   padding:10px 16px !important;
   font-weight: bold;
}

.accordionRoot{
   width:100%!important;
}

.mapButton{
   background-color: #00A4AE!important;
   margin-top:30px!important;
}
.noImage>div{
   background:#f1efea!important;
}


.fredoka{
   font-family: 'Fredoka One', cursive!important;
   color: #3C4858;
}

.raleway{
   font-family: 'Raleway', sans-serif!important;
}

.MuiFab-label a:hover, .Muifab-label a:focus{
   color:white!important;
}

tr.MuiTableRow-root td{
   font-size:12px;
}

tr.MuiTableRow-root td:first-child{
   padding:5px 0px!important;
   padding-right:10px!important;
}

/* tr.MuiTableRow-root td:last-child{
   padding:5px 0px!important;
   padding-left:10px!important;
} */

/* tr.MuiTableRow-root th{
   padding:0!important;
} */

.matTable thead{
   background-color: #FBEECA!important;
}

.matTable th{
   color: #3C4858;
   font-weight: bold;
   font-size: 12px;
}

/* tr.MuiTableRow-root th:last-child{
   padding:5px 0px!important;
   padding-left:10px!important;
} */

tr.MuiTableRow-root th:first-child{
   padding:5px 0px  5px 5px!important;
   padding-right:10px!important;
}

.attachmentButton{
   padding:7px!important;
   font-size:12px!important;
}

.phasesContainer{
   overflow: hidden;
   width:100%;
   padding:0!important;
}

@keyframes lineAnimation {
   0% {width:0; opacity:0.1}
   20%{opacity:1}
   50% {width:100%;}
   90%{opacity:1}
   100% {width:100%;opacity:0;}
 }

 @keyframes lastDotAnimation {
   0% {opacity:0;}
   50% {opacity:1;}
   90% {opacity:1;}
   100% {opacity:0;}
 }
 
 /* @keyframes firstDotAnimation {
   0% {opacity:0;}
   40% {opacity:0;}
   50% {opacity:1;}
   90% {opacity:1;}
   100% {opacity:0;}
 } */
 

.lineContainer{
   width:60%;
   margin:0 auto;
   display: flex;
   justify-content:flex-start;
   align-items: center;
   padding-bottom:10px;
}

.dot{
   width:10px; 
   height: 10px;
   background:#3C4858;
   border-radius:50%;
}

.line{
   width:100%;
   height: 2px;
   background:#3C4858;
   animation-name: lineAnimation;
   animation-duration: 10s;
   animation-delay: 4;
   animation-iteration-count: infinite;
}

.dot.last{
   animation-name: lastDotAnimation;
   animation-duration: 10s;
   animation-iteration-count: infinite;
   animation-delay: 4;
}

.dot.first{
   animation-name: firstDotAnimation;
   animation-duration: 4s;
   animation-iteration-count: infinite;
   animation-delay: 4s;
}

.accordionRoot .MuiAccordionSummary-root {
   padding:0!important;
   min-height: auto!important;
}

.accordionRoot .MuiAccordionSummary-root .MuiAccordionSummary-content.Mui-expanded{
   margin:0px 0;
   min-height: auto!important;
}

/* .accordionRoot .MuiAccordionSummary-expandIcon{
   display:none;
} */

.accordionRoot .MuiAccordionDetails-root{
   padding: 10px 0!important;
}


.accordionRoot.MuiPaper-elevation1{
   box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 0%)!important;
}

.locationIcon{
   display: flex;
   align-items: center;
   margin-top:0px;
   width:120%;
   margin: 0 5%;
}

.locationIcon::before{
   content:'';
   height:1px;
   width:150%;
   background-color: #3D4860;
   display: inline-block;
}

.locationIcon::after{
   content:'';
   height:1px;
   width:150%;
   background-color: #3D4860;
   display: inline-block;
}

/* .inputList{
   border-bottom:1px solid #eaeaea;
} */

/* ::-webkit-scrollbar {
    width: 12px;
    }
   */
/* Track */
 /* ::-webkit-scrollbar-track-piece:start {
    height: 1px;
    -webkit-box-shadow:  inset 0px 0px 6px 0px rgba(0,0,0,1); 
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
::-webkit-scrollbar-track-piece:horizontal:increment{
    background-color: blue;
}
::-webkit-scrollbar-track-piece:horizontal:decrement{
    
}


::-webkit-scrollbar-track-piece:end {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    -webkit-border-radius: 5px;
    border-radius: 10px;
}

::-webkit-scrollbar-track-piece:end:decrement {
   background-color: red;
   -webkit-transition: background-color 2s ease-out;
} */
 
/* Handle */
/* ::-webkit-scrollbar-track:decrement {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(255,0,0,0.8); 
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
} */



/* ::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(255,0,0,0.4); 
} */
  
  .triggerOne {
    height: 1vh;
    background-color: transparent;
  }

  .openTimeStampContainer .MuiTableContainer-root{
     overflow-x: unset!important;
     width:auto!important;
     float: left!important;
  }
  
  
  .hideIcon {
      opacity: 0 !important;
    /* visibility: hidden !important; */
}
  @-webkit-keyframes bounce { 
    0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);} 
    40% {-webkit-transform: translateY(-30px);} 
    60% {-webkit-transform: translateY(-15px);} 
 } 
 
 @keyframes bounce { 
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
    40% {transform: translateY(-30px);} 
    60% {transform: translateY(-15px);} 
 }

 @-webkit-keyframes bounceLeft { 
    0%, 20%, 50%, 80%, 100% {-webkit-transform: translateX(0);} 
    40% {-webkit-transform: translateX(-30px);} 
    60% {-webkit-transform: translateX(-15px);} 
 } 
 
 @keyframes bounceLeft { 
    0%, 20%, 50%, 80%, 100% {-webkit-transform: translateX(0);} 
    40% {-webkit-transform: translateX(-30px);} 
    60% {-webkit-transform: translateX(-15px);} 
 }

 @-webkit-keyframes bounceRight { 
    0%, 20%, 50%, 80%, 100% {-webkit-transform: translateX(0);} 
    40% {-webkit-transform: translateX(30px);} 
    60% {-webkit-transform: translateX(15px);} 
 } 
 
 @keyframes bounceRight { 
    0%, 20%, 50%, 80%, 100% {-webkit-transform: translateX(0);} 
    40% {-webkit-transform: translateX(30px);} 
    60% {-webkit-transform: translateX(15px);} 
 }

.vIndicatorContainer{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:20px 0;
 }

 .vIndicatorContainerTable{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
 }



 .vIconContainer{
    -webkit-animation-name: bounce; 
    animation-name: bounce; 
    animation-duration: 2s;
    animation-iteration-count: infinite;
 }

 .olIconContainer{
    -webkit-animation-name: bounceRight; 
    animation-name: bounceRight; 
    animation-duration: 2s;
    animation-iteration-count: infinite;
 }
 
 .orIconContainer{
    -webkit-animation-name: bounceRight; 
    animation-name: bounceRight; 
    animation-duration: 2s;
    animation-iteration-count: infinite;
 }

 .btnContainer{
    padding:0 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
 }


 @media screen and (max-width:1366px){
 .infoContainer{
   width: 60%; 
   max-width: 60%; 
 } 

 .metaContainer{
   padding:20px;
   padding-bottom:20px;
}

.materialsContainer{
   padding:20px;
   padding-top:0;
}
}

@media screen and (max-width:600px){
    .verifyButton{
        margin-bottom:40px!important;
    }

    .materialsDialog .MuiDialog-paper{
      width:100vw;
      min-height: 100vh;
   }

   .materialsDialog .inputList{
      float:left;
      width:100%;
   }
}  

.customDataRange,.customDataRange .rdrMonths, .customDataRange .rdrMonth {
   max-width: 100%;
   width: 100%;
}

.customDataRange .rdrNextButton{
   display: flex;
   align-items: center;
   justify-content: center;
}

.customDataRange .rdrNextButton i{
   margin: 0;
}

.dateRangeContainer{
   width:40%;
}

.customModal{
   width:100vw;
   height:100vh;
   background: rgba(0,0,0,0.8);
   justify-content: center;
   align-items: center;
   display:flex;
   position: fixed;
   flex-direction: column;
   top:0;
   left:0;
}

.customModal.show{
   opacity:1;
   z-index: 1000000;
   visibility: visible;
   -webkit-transition: all 0.2s ease-in-out;
   -moz-transition: all 0.2s ease-in-out;
   -ms-transition: all 0.2s ease-in-out;
   -o-transition: all 0.2s ease-in-out;
   transition: all 0.2s ease-in-out;
   -webkit-box-shadow: 0 0 10px 0 #474747;
   box-shadow: 0 0 10px 0 #474747;
}

.customModal.hide{
   opacity:0;
   z-index: 1000000;
   visibility: hidden;
   -webkit-transition: all 0.2s ease-in-out;
   -moz-transition: all 0.2s ease-in-out;
   -ms-transition: all 0.2s ease-in-out;
   -o-transition: all 0.2s ease-in-out;
   transition: all 0.2s ease-in-out;
}

.doneContainer{
   background-color: #fafafa;
   padding:10px;
   display: flex;
   justify-content: flex-end;
}

.placeHolderContainer{
   flex-direction:row;
   justify-content: center;
   display:flex;
   align-items: center;
}

h6.MuiTypography-root{
   padding-top:30px;
}

@media screen and (max-width:600px){
   .dateRangeContainer{
       width:100%;
   } 
   
   .placeHolderContainer{
       flex-direction:column;
       display:flex;
       align-items: center;
   }

   .placeHolderContainer > div, .placeHolderContainer button{
       margin:10px 0!important;
   }
}