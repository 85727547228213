table td, table th{
    font-family: "Raleway",sans-serif!important;
}

.allergenesTable td,
.allergenesTable th{
    min-width: 200px;
    padding:10px!important;
}

.allergenesTable tr.MuiTableRow-root td:first-child{
    padding:5px!important;
}

.allergenesTable th{
    font-weight: bold;
}